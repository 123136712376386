import { AddressEdit, Toast } from 'vant';
import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';

const app = createApp(App);

//调试环境
// const env = 'debug';
//测试环境
// const env = 'dev';
//生产环境
const env = 'prod';


let link_icbc = '';
let header = '';
let cheapLink = '';

if (env == 'prod') {
    //// 生产环境参数
    //授信链接
    link_icbc = 'https://m.mall.icbc.com.cn/mobile/member/checkAuthorizationNew.jhtml?outerName=907786&targetUrl=';
    //icbc后台接口域名
    //header = 'http://m.allinte.com:6688';
     header = 'http://m.7cxf.com';
//    cheapLink = 'https://mcoupon.mall.icbc.com.cn/couponDraw?promotionid=202305169456167&code=2224867683446161';
    //优惠商品价格
    // cheapLink = 'https://m.yangshipin.cn/static/project/2024/cvipcard/index.html?channel=ICBC_Oly';
    cheapLink = 'http://m.7cxf.com/#/goods?pid=ysp-lh-48';
} else if (env == 'dev') {
    //// 测试环境参数
    //授信链接
    link_icbc = 'https://mmall.dccnet.com.cn/mobile/member/checkAuthorizationNew.jhtml?outerName=010608&targetUrl=';
    //icbc后台接口域名
    header = 'http://m.7cxf.com';
//    cheapLink = 'http://m.allinte.com:6688/#/goods?pid=ysp-3';
    //优惠商品价格
    //cheapLink = 'https://m.yangshipin.cn/static/project/2024/cvipcard/index.html?channel=ICBC_Oly';
    cheapLink = 'http://m.7cxf.com/#/goods?pid=ysp-lh-48';
} else if (env == 'debug') {
    // 调试环境参数
    //授信链接
    link_icbc = '';
    //icbc后台接口域名
    header = 'http://m.7cxf.com';
    //cheapLink = 'https://m.yangshipin.cn/static/project/2024/cvipcard/index.html?channel=ICBC_Oly';
    cheapLink = 'http://m.7cxf.com/#/goods?pid=ysp-lh-48';
}

app.config.globalProperties.$headerLink = header;
app.config.globalProperties.$cheapLink = cheapLink;
app.config.globalProperties.$apiHeader = header;
app.config.globalProperties.$httpUrl = link_icbc;
app.config.globalProperties.$userInfoUrl = header + '/api/icbc/user2/getUserInfoE'
app.use(router);
app.use(AddressEdit);
app.use(Toast);
app.mount('#app');

export const globals = app.config.globalProperties
